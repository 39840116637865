import React from 'react';
import { connect } from 'react-redux';

import {
  isAuthorizedForPracticeGroup,
  isAuthorizedForTeams,
} from 'Auth/AuthUtils';

import SecondaryNav from 'components/common/nav/SecondaryNav';

import * as routes from 'constants/routes';
import { SETTINGS_ORGANIZATION_NAV_ITEMS } from 'constants/Navigation';

import { getUserInfo } from 'redux/reducers/loginStore';
import { isUserAuthorizedForFeeSchedule } from 'Auth/FeatureFlags';

const Organization = (props) => {
  const filteredNavItems = SETTINGS_ORGANIZATION_NAV_ITEMS.filter((navItem) => {
    if (navItem.to === routes.MANAGER_SETTINGS_ORGANIZATION_PRACTICE_GROUP) {
      return isAuthorizedForPracticeGroup(props.userInfo);
    }

    if (navItem.to === routes.MANAGER_SETTINGS_ORGANIZATION_TEAMS) {
      return isAuthorizedForTeams(props.userInfo);
    }

    if (navItem.to === routes.MANAGER_SETTINGS_ORGANIZATION_FEE_SCHEDULES) {
      return isUserAuthorizedForFeeSchedule(props.userInfo);
    }
    return true;
  });

  return (
    <div>
      <SecondaryNav navItems={filteredNavItems} />
      {props.children}
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);

  return {
    userInfo,
  };
}

export default connect(mapStateToProps, {})(Organization);
