import React, { useState } from 'react';

import * as toast from 'components/Shared/toast';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';

import checkIcon from 'img/appeal_check.svg';

import AppealsAPI from 'API/AppealsAPI';
import { handleError } from 'helpers/errorHandler';

const DeleteSubmission = (props) => {
  const [isDeletingSubmission, setIsDeletingSubmission] = useState(false);

  const {
    onClosePressed,
    onDeleteSubmissionSuccess,
    appeal: {
      submitterClaimId,
      id: appealId,
      claimNumber,
      isMedicalRecordsSubmission,
    },
    includeOutSideClickWrapper = true,
  } = props;
  const actionIdTitle = isMedicalRecordsSubmission
    ? 'Claim ID'
    : 'Payer Claim ID';
  const submissionType = isMedicalRecordsSubmission ? 'record' : 'appeal';
  const claimId = isMedicalRecordsSubmission ? submitterClaimId : claimNumber;
  const popupTitle =
    props.isTriggeredFrom === 'PENDING_ACTIONS'
      ? `Are you sure you want to delete the  ${submissionType} with pending actions for ${actionIdTitle} ${claimId}?`
      : `Are you sure you want to delete the in progress ${submissionType} with ${actionIdTitle} ${claimId}  ?`;

  const deleteSubmission = async () => {
    if (isDeletingSubmission) {
      return;
    }

    setIsDeletingSubmission(true);

    try {
      await AppealsAPI.moveClaimBack(appealId);

      toast.success({
        title: 'Success',
        message: `The submission with Claim ID ${claimId} has been deleted successfully.`,
      });

      setIsDeletingSubmission(false);

      onDeleteSubmissionSuccess();
    } catch (error) {
      handleError(error);
      setIsDeletingSubmission(false);
    }
  };

  const handelActionButtonClick = (index) => {
    if (index === 1) {
      return deleteSubmission();
    }

    onClosePressed();
  };

  return (
    <ActionPopup
      title={popupTitle}
      actionButtons={[
        {
          title: 'No',
        },
        {
          title: 'Yes',
          type: TYPE_ACCEPT,
        },
      ]}
      icon={checkIcon}
      onClosePressed={onClosePressed}
      onActionButtonPressed={handelActionButtonClick}
      includeOutSideClickWrapper={includeOutSideClickWrapper}
    />
  );
};

export default DeleteSubmission;
