import {
  AUTO_ARCHIVE_DQ,
  MANUAL_ERA_UPLOAD,
  BATCH_UPLOAD_MANUAL_CLAIM,
  DISABLE_FAX_COVER_LETTER_FLAG,
  UHC_CLAIM_STATUS_ENABLED_FLAG,
  UHC_API_DIRECT_SUBMISSION,
  AVAILITY_CLAIM_STATUS_ENABLED_FLAG,
  EBRIDGE_SEARCH,
  ODD_PAGE_SUBMISSION_PADDING,
  ENABLE_CLAIM_LINE_FLAG,
  ENABLE_BATCH_SUBMISSION,
  RULE_MINIMUM_BILLED_AMOUNT,
  ENABLE_GLOBAL_SEARCH,
  ENABLE_FEE_SCHEDULE,
} from 'constants/featureFlags';

/**
 * Checks if the feature flag is enabled.
 * @param {Array} featureFlags
 * @param {String} flagName
 * @returns {Boolean}
 */
const isFeatureFlagEnabled = (featureFlags, flagName) => {
  if (!featureFlags) return false;

  return featureFlags[flagName] || false;
};

/**
 * Check if fax cover letter is disabled for the user.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isFaxCoverLetterDisabledForUser = (user) => {
  if (!user) return false;

  return isFeatureFlagEnabled(user.featureFlags, DISABLE_FAX_COVER_LETTER_FLAG);
};

/**
 * Checks if the UHC Claim Status API is enabled for the user.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isUHCClaimStatusCheckEnabledForUser = (user) => {
  if (!user) return false;

  return isFeatureFlagEnabled(user.featureFlags, UHC_CLAIM_STATUS_ENABLED_FLAG);
};

/**
 * Checks if the Availity Claim Status API is enabled for the user.
 * @param {Object} user
 * @returns {Boolean}
 */
export const isAvailityClaimStatusCheckEnabledForUser = (user) => {
  if (!user) return false;

  return isFeatureFlagEnabled(
    user.featureFlags,
    AVAILITY_CLAIM_STATUS_ENABLED_FLAG
  );
};

/**
 * Returns true if user is authorized for given feature flag
 * @param {object} user
 * @param {String} flagName
 * @returns {Boolean}
 */
export const isUserIsAuthorizedForDirectSubmission = (user) => {
  if (!user) return false;

  return isFeatureFlagEnabled(user.featureFlags, UHC_API_DIRECT_SUBMISSION);
};

/**
 * Returns true if user is authorized for given feature flag
 * @param {object} user
 * @param {String} flagName
 * @returns {Boolean}
 */
export const isUserIsAuthorizedForManualEraUpload = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, MANUAL_ERA_UPLOAD);
};

export const isUserAuthorizedForAutoArchiveDQ = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, AUTO_ARCHIVE_DQ);
};

export const isUserAuthorizedForEbridgeSearch = (user) => {
  if (!user) return false;
  return (
    user?.clientExtras?.ebridge &&
    isFeatureFlagEnabled(user.featureFlags, EBRIDGE_SEARCH)
  );
};

export const isUserAuthorizedForOddPageSubmissionPadding = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, ODD_PAGE_SUBMISSION_PADDING);
};

export const isUserAuthorizedForBatchUploadManualClaim = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, BATCH_UPLOAD_MANUAL_CLAIM);
};

export const isUserAuthorizedForClaimLineFlag = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, ENABLE_CLAIM_LINE_FLAG);
};

export const isUserAuthorizedForBatchSubmission = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, ENABLE_BATCH_SUBMISSION);
};

export const isRuleMinimumBilledAmountEnabled = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, RULE_MINIMUM_BILLED_AMOUNT);
};

export const isUserAuthorizedForGlobalSearch = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, ENABLE_GLOBAL_SEARCH);
};

export const isUserAuthorizedForFeeSchedule = (user) => {
  if (!user) return false;
  return isFeatureFlagEnabled(user.featureFlags, ENABLE_FEE_SCHEDULE);
};
