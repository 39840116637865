import { snakeCaseKeys } from 'helpers/object';
import {
  createURL,
  handleErrors,
  createRequest,
  handleJSONParse,
  createJSONFetch,
  createURLWithParams,
  handleErrorsNoRedirect,
  appendAuthTokenIfPresent,
} from './Config';

import { parseUserInfo } from './Mappers';

import * as endpoints from 'constants/endpoints';
import {
  DISABLE_FAX_COVER_LETTER_FLAG,
  UHC_CLAIM_STATUS_ENABLED_FLAG,
  MANUAL_ERA_UPLOAD,
  AVAILITY_CLAIM_STATUS_ENABLED_FLAG,
  AUTO_ARCHIVE_DQ,
  EBRIDGE_SEARCH,
  ODD_PAGE_SUBMISSION_PADDING,
  BATCH_UPLOAD_MANUAL_CLAIM,
  ENABLE_CLAIM_LINE_FLAG,
  ENABLE_BATCH_SUBMISSION,
  RULE_MINIMUM_BILLED_AMOUNT,
  ENABLE_GLOBAL_SEARCH,
  ENABLE_FEE_SCHEDULE,
} from 'constants/featureFlags';

export default class AuthAPI {
  static authenticate(username, password) {
    const request = createRequest('sign-in', {
      username,
      password,
    });

    return createJSONFetch(request).then((authInfo) => {
      const token = authInfo.token;
      return AuthAPI.getUserInfo(token).then((userInfo) => {
        return Promise.resolve({
          authToken: token,
          userInfo,
        });
      });
    });
  }

  static sendForgotPasswordEmail(email, shouldCreatePassword = false) {
    const params = {};

    if (shouldCreatePassword) {
      params.create = 'true';
    }

    const request = createRequest('password/reset', { email }, { params });

    return fetch(request)
      .then(handleErrors)
      .then((res) => {
        return Promise.resolve({ email });
      });
  }

  static resetPassword(uid, token, newPassword, isCreate = false) {
    const params = {};

    if (isCreate) {
      params.create = 'true';
    }

    const request = createRequest(
      'password/confirm',
      { uid, token, new_password: newPassword },
      { params }
    );
    return fetch(request)
      .then(handleErrors)
      .then((res) => {
        return Promise.resolve({ newPassword });
      });
  }

  static async getUserInfo(token, redirectToLoginOnFailure = true) {
    const userRequest = new Request(
      createURLWithParams(endpoints.USERS_ME, {
        include: 'client',
      }),
      {
        method: 'GET',
        headers: appendAuthTokenIfPresent({}, token),
      }
    );

    const errorHandler = redirectToLoginOnFailure
      ? handleErrors
      : handleErrorsNoRedirect;

    const userInfo = await fetch(userRequest)
      .then(errorHandler)
      .then(handleJSONParse)
      .then(parseUserInfo);

    const clientConfig = snakeCaseKeys(userInfo?.clientConfig || {});

    const flagsToCheck = [
      DISABLE_FAX_COVER_LETTER_FLAG,
      UHC_CLAIM_STATUS_ENABLED_FLAG,
      MANUAL_ERA_UPLOAD,
      AVAILITY_CLAIM_STATUS_ENABLED_FLAG,
      AUTO_ARCHIVE_DQ,
      EBRIDGE_SEARCH,
      ODD_PAGE_SUBMISSION_PADDING,
      BATCH_UPLOAD_MANUAL_CLAIM,
      ENABLE_CLAIM_LINE_FLAG,
      ENABLE_BATCH_SUBMISSION,
      RULE_MINIMUM_BILLED_AMOUNT,
      ENABLE_GLOBAL_SEARCH,
      ENABLE_FEE_SCHEDULE,
    ];

    const featureFlags = await this.isFeatureFlagsEnabled(token, flagsToCheck);

    return {
      ...userInfo,
      featureFlags,
      clientConfig,
    };
  }

  static signOut(token) {
    const request = createRequest('sign-out', { token });

    return fetch(request)
      .then(handleErrors)
      .then((res) => res);
  }

  /**
   * Checks if a feature flag is enabled.
   * @param {Array<String>} flagNames Array of flag names to check
   * @returns {Promise<Object>} Object with flag names as keys and boolean values indicating if they are enabled
   */
  static isFeatureFlagsEnabled = async (token, flagNames) => {
    const flagParams = flagNames
      .map((flagName) => `flag_name=${encodeURIComponent(flagName)}`)
      .join('&');
    const url = `${createURL(endpoints.FEATURE_FLAGS)}?${flagParams}`;

    const request = new Request(url, {
      method: 'GET',
      headers: appendAuthTokenIfPresent({}, token),
    });

    return await fetch(request)
      .then(handleErrors)
      .then(handleJSONParse)
      .then((flagsStatus) => {
        return Promise.resolve(flagsStatus);
      })
      .catch(() => {
        const errorResponse = flagNames.reduce((acc, flagName) => {
          acc[flagName] = false;
          return acc;
        }, {});
        return Promise.resolve(errorResponse);
      });
  };
}
