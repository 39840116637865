import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MdOutlineEventNote, MdOutlineSave } from 'react-icons/md';

import closeIcon from 'img/close-modal.svg';

import NotesList from './NotesList';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import { useCreateNote, useFetchNotes } from '../hooks/useFetchNotes';

import { handleError } from 'helpers/errorHandler';

const CHARACTERS_LIMIT = 500;

const NotesPopup = (props) => {
  const {
    queryParams = {},
    claimId = '',
    claimNumber = '',
    setShowNotesPopup,
    disableAddNote = false,
    appealId = '',
    dqId = '',
    isAppeal,
  } = props;

  const [note, setNote] = useState('');

  const handleInputChange = (e) => {
    setNote(e.target.value);
  };

  const title = claimId
    ? `Note(s) for Claim Id: ${claimId}`
    : `Note(s) for Claim Control Number: ${claimNumber}`;

  const selectedId = isAppeal ? appealId : dqId;

  const {
    notes,
    loading: isLoadingNotes,
    fetch: fetchSubmissionsNotes,
    setNotes,
  } = useFetchNotes(selectedId, isAppeal);

  const { createNote, isCreatingNotes } = useCreateNote(
    isAppeal,
    fetchSubmissionsNotes,
    handleError
  );

  const handleCreateNote = async (e) => {
    e.stopPropagation(e);
    setNote('');
    const newNote = await createNote(note, selectedId, queryParams);
    setNotes((prevNotes) => [newNote, ...prevNotes]);
  };

  return (
    <ScreenDimmerComponent>
      <div
        className={
          'ap-popup notes-popup ap-popup--overflow--visible ap-popup--p-0'
        }
      >
        <div className="ap-popup__header ap-popup__header--with-border">
          <div className="ap-popup__title">
            <MdOutlineEventNote size="24" className="mr-8" />
            <h4>{title}</h4>
          </div>
          <div
            className="ap-popup__close"
            onClick={() => {
              setShowNotesPopup(false);
            }}
          >
            <img alt="Close" className="ap-popup__close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="ap-popup__content">
          {isLoadingNotes ? (
            <LoadingIndicator showing={true} />
          ) : (
            <NotesList notes={notes} />
          )}
        </div>
        <div className="ap-popup__footer ap-popup__footer--fixed">
          <div className="input-note-wrapper">
            <textarea
              maxLength={CHARACTERS_LIMIT}
              value={note}
              placeholder="Add your note here"
              className="input-note"
              autoFocus
              rows={1}
              onChange={handleInputChange}
              disabled={disableAddNote}
            />
            <button
              className="ap-button ap-button--secondary"
              disabled={!note || disableAddNote || isCreatingNotes}
              onClick={handleCreateNote}
            >
              <MdOutlineSave size="16" className="mr-8" />
              <span>Save</span>
            </button>
          </div>
          <p className="mt-8 mb-0 mr-4 fw-500 fs-12 text-right">
            {note.length}/{CHARACTERS_LIMIT}
          </p>
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

NotesPopup.propTypes = {
  claimId: PropTypes.string.isRequired,
  setShowNotesPopup: PropTypes.func.isRequired,
};

export default NotesPopup;
